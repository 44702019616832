import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const FileImage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              name
              relativePath
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 112, height: 112)
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n) => {
        return n.node.relativePath.includes(props.filename);
      });

      if (image && image.node.childImageSharp) {
        return (
          <GatsbyImage
            alt={props.alt}
            image={image.node.childImageSharp.gatsbyImageData}
            className="w-16 h-16 md:w-20 md:h-20 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
            imgStyle={{ borderRadius: "9999px" }}
            {...props}
          />
        );
      } else {
        console.warn("Image file not found for %s", props.filename);
        return (
          <img
            alt={props.alt}
            src="https://dummyimage.com/84x84"
            className="w-16 h-16 md:w-20 md:h-20 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
            {...props}
          />
        );
      }
    }}
  />
);

FileImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  filename: PropTypes.string.isRequired,
};

export default FileImage;
