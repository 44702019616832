const GOV_MY = "gov-my";
const UNION_MINISTER_MY = "ပြည်ထောင်စုဝန်ကြီး";
const DEPUTY_MINISTER_MY = "ဒုတိယဝန်ကြီး";
const MOD_MY = "mod-my";
const MOH_MY = "moh-my";
const MOPFI_MY = "mopfi-my";
const MOFA_MY = "mofa-my";
const MOE_MY = "moe-my";
const MOHAI_MY = "mohai-my";
const MOFUA_MY = "mofua-my";
const MOHADM_MY = "mohadm-my";
const MOHR_MY = "mohr-my";
const MONREC_MY = "moh-my";
const MOIC_MY = "moic-my";
const MOWYC_MY = "mowyc-my";
const MOL_MY = "mol-my";
const MCIT_MY = "mcit-my";
const MOC_MY = "moc-my";

const MOJ_MY = "moj-my";
const MOCIT_MY = "mocit-my";
const MOEE_MY = "moee-my";

module.exports = {
  heads_my: [
    {
      name: "ဦးဝင်းမြင့်",
      portfolio: "နိုင်ငံတော်သမ္မတ",
      category: GOV_MY,
      url: "https://gov.nugmyanmar.org/my/president-u-win-myint-my/",
      logo: "https://dummyimage.com/84x84",
      member_thumbnail: "heads/UWM.jpg",
    },
    {
      name: "ဒူဝါလရှီးလ",
      portfolio: "နိုင်ငံတော်ယာယီသမ္မတ", //"နိုင်ငံတော်ဒုတိယသမ္မတ",
      category: GOV_MY,
      url: "https://gov.nugmyanmar.org/my/vice-president-duwa-lashi-la-my/",
      logo: "https://dummyimage.com/84x84",
      member_thumbnail: "heads/DLS.jpg",
    },
    {
      name: "ဒေါ်အောင်ဆန်းစုကြည်",
      portfolio: "နိုင်ငံတော်၏အတိုင်ပင်ခံပုဂ္ဂိုလ်",
      category: GOV_MY,
      url: "https://gov.nugmyanmar.org/my/state-counsellor-daw-aung-san-suu-kyi-my/",
      logo: "https://dummyimage.com/84x84",
      member_thumbnail: "heads/DASSK.jpg",
    },
    {
      name: "မန်းဝင်းခိုင်သန်း",
      portfolio: "ပြည်ထောင်စုဝန်ကြီးချုပ်",
      category: GOV_MY,
      url: "https://gov.nugmyanmar.org/my/prime-minister-mahn-winn-khaing-thann-my/",
      logo: "https://dummyimage.com/84x84",
      member_thumbnail: "heads/MWKT.jpg",
    },
    {
      name: "အမျိုးသားညီညွတ်ရေးအစိုးရ",
      portfolio: "သတင်းအချက်အလက်နှင့်ဝန်ဆောင်မှုများ",
      category: GOV_MY,
      url: "https://gov.nugmyanmar.org/my/",
      logo: "https://dummyimage.com/84x84",
      member_thumbnail: "heads/GOVMYANMAR.jpg",
    },
  ],
  ministries_my: [
    {
      index: 0,
      portfolio: "ကာကွယ်ရေးဝန်ကြီးဌာန",
      category: MOD_MY,
      logo: "ministries/mod_my.png",
      url: "https://mod.nugmyanmar.org/my/",
      members: [
        {
          name: "ဦးရည်မွန်",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/YM.jpg",
          url: "https://mod.nugmyanmar.org/my/union-minister/",
        },
        {
          name: "နိုင်ကောင်းရွက် (MSICC)",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/NKR.jpg",
          url: "https://mod.nugmyanmar.org/my/deputy-ministers/",
        },
      ],
    },
    {
      index: 1,
      portfolio: "ကျန်းမာရေးဝန်ကြီးဌာန",
      category: MOH_MY,
      logo: "ministries/moh.png",
      url: "https://moh.nugmyanmar.org/my/",
      members: [
        {
          name: "ဒေါက်တာဇော်ဝေစိုး",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/ZWS.jpg",
          url: "https://moh.nugmyanmar.org/my/biography-minister/",
        },
        {
          name: "ဒေါက်တာရွှေပုံ",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/SP.jpg",
          url: "https://moh.nugmyanmar.org/my/biography-deputy-minister/",
        },
      ],
    },
    {
      index: 2,
      portfolio: "စီမံကိန်း၊ ဘဏ္ဍာရေးနှင့် ရင်းနှီးမြှုပ်နှံမှုဝန်ကြီးဌာန",
      category: MOPFI_MY,
      logo: "ministries/mopfi.png",
      url: "https://mopfi.nugmyanmar.org/my/",
      members: [
        {
          name: "ဦးတင်ထွန်းနိုင်",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/TTN.jpg",
          url: "https://mopfi.nugmyanmar.org/my/union-minister/",
        },
        {
          name: "ဦးမင်းဇေယျာဦး",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/MZYO.jpg",
          url: "https://mopfi.nugmyanmar.org/my/deputy-minister/",
        },
      ],
    },
    {
      index: 3,
      portfolio: "နိုင်ငံခြားရေးဝန်ကြီးဌာန",
      category: MOFA_MY,
      logo: "ministries/mofa.png",
      url: "https://mofa.nugmyanmar.org/my/",
      members: [
        {
          name: "ဒေါ်ဇင်မာအောင်",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/ZMA.jpg",
          url: "https://mofa.nugmyanmar.org/my/union-minister-my/",
        },
        {
          name: "ဦးမိုးဇော်ဦး",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/MZO.jpg",
          url: "https://mofa.nugmyanmar.org/my/deputy-minister-my/",
        },
      ],
    },
    {
      index: 4,
      portfolio: "ပညာရေးဝန်ကြီးဌာန",
      category: MOE_MY,
      logo: "ministries/moe.png",
      url: "https://moe.nugmyanmar.org/",
      members: [
        {
          name: "ဒေါက်တာဇော်ဝေစိုး",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/ZWS_moe.jpg",
          url:
            "https://moe.nugmyanmar.org/%e1%80%95%e1%80%bc%e1%80%8a%e1%80%ba%e1%80%91%e1%80%b1%e1%80%ac%e1%80%84%e1%80%ba%e1%80%85%e1%80%af%e1%80%9d%e1%80%94%e1%80%ba%e1%80%80%e1%80%bc%e1%80%ae%e1%80%b8/",
        },
        {
          name: "ဂျာထွယ်ပန်",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/JHP.jpg",
          url:
            "https://moe.nugmyanmar.org/%e1%80%95%e1%80%bc%e1%80%8a%e1%80%ba%e1%80%91%e1%80%b1%e1%80%ac%e1%80%84%e1%80%ba%e1%80%85%e1%80%af-%e1%80%92%e1%80%af%e1%80%9d%e1%80%94%e1%80%ba%e1%80%80%e1%80%bc%e1%80%ae%e1%80%b8%e1%80%99%e1%80%bb/",
        },
        {
          name: "ဒေါက်တာစိုင်းခိုင်မျိုးထွန်း",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/SKMT.jpg",
          url:
            "https://moe.nugmyanmar.org/%e1%80%95%e1%80%bc%e1%80%8a%e1%80%ba%e1%80%91%e1%80%b1%e1%80%ac%e1%80%84%e1%80%ba%e1%80%85%e1%80%af-%e1%80%92%e1%80%af%e1%80%9d%e1%80%94%e1%80%ba%e1%80%80%e1%80%bc%e1%80%ae%e1%80%b8%e1%80%99%e1%80%bb/",
        },
      ],
    },
    {
      index: 5,
      portfolio: "ပြည်ထဲရေးနှင့် လူဝင်မှုကြီးကြပ်ရေးဝန်ကြီးဌာန",
      category: MOHAI_MY,
      logo: "ministries/mohai.png",
      url: "https://mohai.nugmyanmar.org/",
      members: [
        {
          name: "ဦးလွင်ကိုလတ်",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/LKL.jpg",
          url:
            "https://mohai.nugmyanmar.org/%e1%80%95%e1%80%bc%e1%80%8a%e1%80%ba%e1%80%91%e1%80%b1%e1%80%ac%e1%80%84%e1%80%ba%e1%80%85%e1%80%af%e1%80%9d%e1%80%94%e1%80%ba%e1%80%80%e1%80%bc%e1%80%ae%e1%80%b8/",
        },
        {
          name: "ခူထဲဘူး",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/KHB.jpg",
          url:
            "https://mohai.nugmyanmar.org/%e1%80%95%e1%80%bc%e1%80%8a%e1%80%ba%e1%80%91%e1%80%b1%e1%80%ac%e1%80%84%e1%80%ba%e1%80%85%e1%80%af-%e1%80%92%e1%80%af%e1%80%9d%e1%80%94%e1%80%ba%e1%80%80%e1%80%bc%e1%80%ae%e1%80%b8/",
        },
      ],
    },
    {
      index: 6,
      portfolio: "ဖက်ဒရယ်ပြည်ထောင်စုရေးရာဝန်ကြီးဌာန",
      category: MOFUA_MY,
      logo: "ministries/mofua.png",
      url: "https://mofua.nugmyanmar.org/",
      members: [
        {
          name: "ဒေါက်တာလျန်မှုန်းဆာခေါင်း",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/LHS.jpg",
          url: "https://mofua.nugmyanmar.org/minister/5",
        },
        {
          name: "ဦးချစ်ထွန်း",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/CT.png",
          url: "https://mofua.nugmyanmar.org/minister/15",
        },
        {
          name: "မိုင်းဝင်းထူး",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/MWH.jpg",
          url: "https://mofua.nugmyanmar.org/minister/25",
        },
      ],
    },
    {
      index: 7,
      portfolio: "လူသားချင်းစာနာထောက်ထားရေး နှင့် ဘေးအန္တရာယ်ဆိုင်ရာစီမံခန့်ခွဲရေးဝန်ကြီးဌာန",
      category: MOHADM_MY,
      logo: "ministries/mohadm_my.png",
      url: "https://mohadm.nugmyanmar.org/my/",
      members: [
        {
          name: "ဒေါက်တာဝင်းမြတ်အေး",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/WMA.jpg",
          url: "https://mohadm.nugmyanmar.org/my/union-minister-my/",
        },
        {
          name: "နော်ထူးဖော",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/NHP.jpg",
          url: "https://mohadm.nugmyanmar.org/my/deputy-minister-my/",
        },
      ],
    },
    {
      index: 8,
      portfolio: "လူ့အခွင့်အရေးဆိုင်ရာဝန်ကြီးဌာန",
      category: MOHR_MY,
      logo: "ministries/mohr.png",
      url: "https://mohr.nugmyanmar.org/",
      members: [
        {
          name: "ဦးအောင်မျိုးမင်း",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/AMM.jpg",
          url:
            "https://mohr.nugmyanmar.org/%e1%80%95%e1%80%bc%e1%80%8a%e1%80%ba%e1%80%91%e1%80%b1%e1%80%ac%e1%80%84%e1%80%ba%e1%80%85%e1%80%af%e1%80%9d%e1%80%94%e1%80%ba%e1%80%80%e1%80%bc%e1%80%ae%e1%80%b8/",
        },
        {
          name: "ဗဟန်းထန်",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/BHH.jpg",
          url:
            "https://mohr.nugmyanmar.org/%e1%80%95%e1%80%bc%e1%80%8a%e1%80%ba%e1%80%91%e1%80%b1%e1%80%ac%e1%80%84%e1%80%ba%e1%80%85%e1%80%af-%e1%80%92%e1%80%af%e1%80%9d%e1%80%94%e1%80%ba%e1%80%80%e1%80%bc%e1%80%ae%e1%80%b8/",
        },
      ],
    },
    {
      index: 9,
      portfolio: "သယံဇာတနှင့် သဘာဝပတ်ဝန်းကျင် ထိန်းသိမ်းရေးဝန်ကြီးဌာန",
      category: MONREC_MY,
      logo: "ministries/monrec.png",
      url: "https://monrec.nugmyanmar.org/my/",
      members: [
        {
          name: "ဒေါက်တာတူးခေါင်",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/TK.jpg",
          url: "https://monrec.nugmyanmar.org/my/biography-union-minister/",
        },
        {
          name: "ခွန်းဘီထူး",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/KBD.jpg",
          url: "https://monrec.nugmyanmar.org/my/biography-deputy-minister/",
        },
      ],
    },
    {
      index: 10,
      portfolio: "အပြည်ပြည်ဆိုင်ရာပူးပေါင်းဆောင်ရွက်ရေးဝန်ကြီးဌာန",
      category: MOIC_MY,
      logo: "ministries/moic.png",
      url: "https://moic.nugmyanmar.org/my/home-mm/",
      members: [
        {
          name: "ဒေါက်တာဆာဆာ",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/SS.jpg",
          url:
            "https://moic.nugmyanmar.org/my/h-e-%e1%80%92%e1%80%b1%e1%80%ab%e1%80%80%e1%80%ba%e1%80%90%e1%80%ac%e1%80%86%e1%80%ac%e1%80%86%e1%80%ac/",
        },
        {
          name: "ခေါင်နော",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/HKN.jpg",
          url:
            "https://moic.nugmyanmar.org/my/h-e-%e1%80%81%e1%80%b1%e1%80%ab%e1%80%84%e1%80%ba%e1%80%94%e1%80%b1%e1%80%ac/",
        },
      ],
    },
    {
      index: 11,
      portfolio: "အမျိုးသမီး၊ လူငယ်နှင့် ကလေးသူငယ်ရေးရာဝန်ကြီးဌာန",
      category: MOWYC_MY,
      logo: "ministries/mowyc.png",
      url: "https://mowyca.nugmyanmar.org/my/",
      members: [
        {
          name: "နော်စူစန်နာလှလှစိုး",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/NSHHS.jpg",
          url: "https://mowyca.nugmyanmar.org/my/about/minister",
        },
        {
          name: "ဒေါ်အိသဉ္ဇာမောင်",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/ETZM.jpg",
          url: "https://mowyca.nugmyanmar.org/my/about/deputy-minister",
        },
      ],
    },
    {
      index: 12,
      portfolio: "အလုပ်သမားဝန်ကြီးဌာန",
      category: MOL_MY,
      logo: "ministries/mol.png",
      url: "https://mol.nugmyanmar.org/my/",
      members: [
        {
          name: "နိုင်ထွန်း​ဖေ (ခ) နိုင်သုဝဏ္ဏ",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/NTWN.jpg",
          url: "https://mol.nugmyanmar.org/my/ministers/union-minister-biography/",
        },
        {
          name: "ဦးကျော်နီ (ခ) ဦးကျော်ကျော်",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/UKN.jpg",
          url: "https://mol.nugmyanmar.org/my/ministers/union-deputy-minister-biography/",
        },
      ],
    },
    {
      index: 13,
      portfolio: "တရားရေးဝန်ကြီးဌာန",
      category: MOJ_MY,
      logo: "ministries/moj.png",
      url: "",
      members: [
        {
          name: "ဦးသိန်းဦး",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/UTO.jpg",
          url: "",
        },
      ],
    },
    {
      index: 14,
      portfolio: "ဆက်သွယ်ရေး၊ သတင်းအချက်အလက်နှင့် နည်းပညာဝန်ကြီးဌာန",
      category: MOCIT_MY,
      logo: "ministries/mocit.png",
      url: "https://mocit.nugmyanmar.org/my/",
      members: [
        {
          name: "ဦးထင်လင်းအောင်",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/UHLA.jpg",
          url: "https://mocit.nugmyanmar.org/my/union-minister/",
        },
      ],
    },
    {
      index: 15,
      portfolio: "လျှပ်စစ်နှင့် စွမ်းအင်ဝန်ကြီးဌာန",
      category: MOEE_MY,
      logo: "ministries/moee.png",
      url: "https://moee.nugmyanmar.org/",
      members: [
        {
          name: "ဦးစိုးသူရထွန်း",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/USTRT.jpg",
          url: "https://moee.nugmyanmar.org/union-minister/",
        },
        {
          name: "လဖိုင်မော်ထွန်းအောင်",
          position: DEPUTY_MINISTER_MY,
          member_thumbnail: "ministers/deputies/MTA.jpg",
          url: "https://moee.nugmyanmar.org/deputy-minister/",
        },
      ],
    },
    {
      index: 16,
      portfolio: "စီးပွားရေးနှင့် ကူးသန်းရောင်းဝယ်ရေးဝန်ကြီးဌာန",
      category: MOC_MY,
      logo: "ministries/moc.png",
      url: "",
      members: [
        {
          name: "ဒေါ်ခင်မမမျိုး",
          position: UNION_MINISTER_MY,
          member_thumbnail: "ministers/deputies/KMMM.jpg",
          url: "",
        },
      ],
    },
  ],
  GOV_MY,
  UNION_MINISTER_MY,
  DEPUTY_MINISTER_MY,
  MOD_MY,
  MOH_MY,
  MOPFI_MY,
  MOFA_MY,
  MOE_MY,
  MOHAI_MY,
  MOFUA_MY,
  MOHADM_MY,
  MOHR_MY,
  MONREC_MY,
  MOIC_MY,
  MOWYC_MY,
  MOL_MY,
  MCIT_MY,
  MOC_MY,

  MOJ_MY,
  MOEE_MY,
  MOCIT_MY,
};
