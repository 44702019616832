import { Buffer } from "buffer";

// Category is usually <MINISTRY_CODE>-<LANG>
// examples: MOFA-en, MOD-my
export const createNewsRouteFromCategory = (category, postUri) => {
  const [ministryCode, language] = category.split("-");
  const base64Uri = Buffer.from(postUri).toString("base64");
  const newsPath = `/${ministryCode.toLowerCase()}/news/${base64Uri}`;
  return language === "en" ? `/${language.toLowerCase()}${newsPath}` : newsPath;
};

export const sortString = (first, second) => {
  let fa = first.toLowerCase(),
    fb = second.toLowerCase();
  if (fa < fb) return -1;
  if (fa > fb) return 1;
  return 0;
};

export const sortNumber = (first, second) => {
  if (first < second) return -1;
  if (first > second) return 1;
  return 0;
};
