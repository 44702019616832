const GOV_EN = "gov-en";
const UNION_MINISTER_EN = "Union Minister";
const DEPUTY_MINISTER_EN = "Deputy Minister";
const MOD_EN = "mod-en";
const MOH_EN = "moh-en";
const MOPFI_EN = "mopfi-en";
const MOFA_EN = "mofa-en";
const MOE_EN = "moe-en";
const MOHAI_EN = "mohai-en";
const MOFUA_EN = "mofua-en";
const MOHADM_EN = "mohadm-en";
const MOHR_EN = "mohr-en";
const MONREC_EN = "moh-en";
const MOIC_EN = "moic-en";
const MOWYC_EN = "mowyc-en";
const MOL_EN = "mol-en";
const MCIT_EN = "mcit-en";
const MOC_EN = "moc-en";

const MOJ_EN = "moj-en";
const MOCIT_EN = "mocit-en";
const MOEE_EN = "moee-en";

module.exports = {
  heads_en: [
    {
      name: "U Win Myint",
      portfolio: "President",
      category: GOV_EN,
      url: "https://gov.nugmyanmar.org/president-u-win-myint/",
      logo: "https://dummyimage.com/84x84",
      member_thumbnail: "heads/UWM.jpg",
    },
    {
      name: "Duwa Lashi La",
      portfolio: "Acting President", //"Vice President",
      category: GOV_EN,
      url: "https://gov.nugmyanmar.org/vice-president-duwa-lashi-la/",
      logo: "https://dummyimage.com/84x84",
      member_thumbnail: "heads/DLS.jpg",
    },
    {
      name: "Daw Aung San Suu Kyi",
      portfolio: "State Counsellor",
      category: GOV_EN,
      url: "https://gov.nugmyanmar.org/state-counsellor-daw-aung-san-suu-kyi/",
      logo: "https://dummyimage.com/84x84",
      member_thumbnail: "heads/DASSK.jpg",
    },
    {
      name: "Mahn Winn Khaing Thann",
      portfolio: "Prime Minister",
      category: GOV_EN,
      url: "https://gov.nugmyanmar.org/prime-minister-mahn-winn-khaing-thann/",
      logo: "https://dummyimage.com/84x84",
      member_thumbnail: "heads/MWKT.jpg",
    },
    {
      name: "National Unity Government",
      portfolio: "Information and Services",
      category: GOV_EN,
      url: "https://gov.nugmyanmar.org/",
      logo: "https://dummyimage.com/84x84",
      member_thumbnail: "heads/GOVMYANMAR.jpg",
    },
  ],
  ministries_en: [
    {
      index: 0,
      portfolio: "Ministry of Defence",
      category: MOD_EN,
      logo: "ministries/mod_en.png",
      url: "https://mod.nugmyanmar.org/en/",
      members: [
        {
          name: "U Yee Mon",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/YM.jpg",
          url: "https://mod.nugmyanmar.org/en/union-minister/",
        },
        {
          name: "Nai Kao Rot (MSICC)",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/NKR.jpg",
          url: "https://mod.nugmyanmar.org/en/deputy-ministers/",
        },
      ],
    },
    {
      index: 1,
      portfolio: "Ministry of Health",
      category: MOH_EN,
      logo: "ministries/moh.png",
      url: "https://moh.nugmyanmar.org/",
      members: [
        {
          name: "Dr. Zaw Wai Soe",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/ZWS.jpg",
          url: "https://moh.nugmyanmar.org/biography-minister/",
        },
        {
          name: "Dr. Shwe Pon",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/SP.jpg",
          url: "https://moh.nugmyanmar.org/biography-deputy-minister/",
        },
      ],
    },
    {
      index: 2,
      portfolio: "Ministry of Planning, Finance and Investment",
      category: MOPFI_EN,
      logo: "ministries/mopfi.png",
      url: "https://mopfi.nugmyanmar.org/",
      members: [
        {
          name: "U Tin Tun Naing",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/TTN.jpg",
          url: "https://mopfi.nugmyanmar.org/union-minister/",
        },
        {
          name: "U Min Zayar Oo",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/MZYO.jpg",
          url: "https://mopfi.nugmyanmar.org/deputy-minister/",
        },
      ],
    },
    {
      index: 3,
      portfolio: "Ministry of Foreign Affairs",
      category: MOFA_EN,
      logo: "ministries/mofa.png",
      url: "https://mofa.nugmyanmar.org/",
      members: [
        {
          name: "Daw Zin Mar Aung",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/ZMA.jpg",
          url: "https://mofa.nugmyanmar.org/union-minister/",
        },
        {
          name: "U Moe Zaw Oo",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/MZO.jpg",
          url: "https://mofa.nugmyanmar.org/deputy-minister/",
        },
      ],
    },
    {
      index: 4,
      portfolio: "Ministry of Education",
      category: MOE_EN,
      logo: "ministries/moe.png",
      url: "https://moe.nugmyanmar.org/en/",
      members: [
        {
          name: "Dr. Zaw Wai Soe",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/ZWS_moe.jpg",
          url:
            "https://moe.nugmyanmar.org/en/%e1%80%95%e1%80%bc%e1%80%8a%e1%80%ba%e1%80%91%e1%80%b1%e1%80%ac%e1%80%84%e1%80%ba%e1%80%85%e1%80%af%e1%80%9d%e1%80%94%e1%80%ba%e1%80%80%e1%80%bc%e1%80%ae%e1%80%b8/",
        },
        {
          name: "Ja Htoi Pan",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/JHP.jpg",
          url:
            "https://moe.nugmyanmar.org/en/%e1%80%95%e1%80%bc%e1%80%8a%e1%80%ba%e1%80%91%e1%80%b1%e1%80%ac%e1%80%84%e1%80%ba%e1%80%85%e1%80%af-%e1%80%92%e1%80%af%e1%80%9d%e1%80%94%e1%80%ba%e1%80%80%e1%80%bc%e1%80%ae%e1%80%b8%e1%80%99%e1%80%bb/",
        },
        {
          name: "Dr. Sai Khaing Myo Tun",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/SKMT.jpg",
          url:
            "https://moe.nugmyanmar.org/en/%e1%80%95%e1%80%bc%e1%80%8a%e1%80%ba%e1%80%91%e1%80%b1%e1%80%ac%e1%80%84%e1%80%ba%e1%80%85%e1%80%af-%e1%80%92%e1%80%af%e1%80%9d%e1%80%94%e1%80%ba%e1%80%80%e1%80%bc%e1%80%ae%e1%80%b8%e1%80%99%e1%80%bb/",
        },
      ],
    },
    {
      index: 5,
      portfolio: "Ministry of Home Affairs and Immigration",
      category: MOHAI_EN,
      logo: "ministries/mohai.png",
      url: "https://mohai.nugmyanmar.org/en/",
      members: [
        {
          name: "U Lwin Ko Latt",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/LKL.jpg",
          url:
            "https://mohai.nugmyanmar.org/en/%e1%80%95%e1%80%bc%e1%80%8a%e1%80%ba%e1%80%91%e1%80%b1%e1%80%ac%e1%80%84%e1%80%ba%e1%80%85%e1%80%af%e1%80%9d%e1%80%94%e1%80%ba%e1%80%80%e1%80%bc%e1%80%ae%e1%80%b8/",
        },
        {
          name: "Khu Hte Bu",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/KHB.jpg",
          url:
            "https://mohai.nugmyanmar.org/en/%e1%80%95%e1%80%bc%e1%80%8a%e1%80%ba%e1%80%91%e1%80%b1%e1%80%ac%e1%80%84%e1%80%ba%e1%80%85%e1%80%af-%e1%80%92%e1%80%af%e1%80%9d%e1%80%94%e1%80%ba%e1%80%80%e1%80%bc%e1%80%ae%e1%80%b8/",
        },
      ],
    },
    {
      index: 6,
      portfolio: "Ministry of Federal Union Affairs",
      category: MOFUA_EN,
      logo: "ministries/mofua.png",
      url: "https://mofua.nugmyanmar.org/?lang=en",
      members: [
        {
          name: "Dr. Lian Hmung Sakhong",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/LHS.jpg",
          url: "https://mofua.nugmyanmar.org/minister/5?lang=en",
        },
        {
          name: "U Chit Tun",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/CT.png",
          url: "https://mofua.nugmyanmar.org/minister/15?lang=en",
        },
        {
          name: "Mai Win Htoo",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/MWH.jpg",
          url: "https://mofua.nugmyanmar.org/minister/25?lang=en",
        },
      ],
    },
    {
      index: 7,
      portfolio: "Ministry of Humanitarian Affairs and Disaster Management",
      category: MOHADM_EN,
      logo: "ministries/mohadm_en.png",
      url: "https://mohadm.nugmyanmar.org/",
      members: [
        {
          name: "Dr. Win Myat Aye",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/WMA.jpg",
          url: "https://mohadm.nugmyanmar.org/union-minister/",
        },
        {
          name: "Naw Htoo Phaw",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/NHP.jpg",
          url: "https://mohadm.nugmyanmar.org/deputy-minister/",
        },
      ],
    },
    {
      index: 8,
      portfolio: "Ministry of Human Rights",
      category: MOHR_EN,
      logo: "ministries/mohr.png",
      url: "https://mohr.nugmyanmar.org/en/",
      members: [
        {
          name: "U Aung Myo Min",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/AMM.jpg",
          url:
            "https://mohr.nugmyanmar.org/en/%e1%80%95%e1%80%bc%e1%80%8a%e1%80%ba%e1%80%91%e1%80%b1%e1%80%ac%e1%80%84%e1%80%ba%e1%80%85%e1%80%af%e1%80%9d%e1%80%94%e1%80%ba%e1%80%80%e1%80%bc%e1%80%ae%e1%80%b8/",
        },
        {
          name: "Baham Htan",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/BHH.jpg",
          url:
            "https://mohr.nugmyanmar.org/en/%e1%80%95%e1%80%bc%e1%80%8a%e1%80%ba%e1%80%91%e1%80%b1%e1%80%ac%e1%80%84%e1%80%ba%e1%80%85%e1%80%af-%e1%80%92%e1%80%af%e1%80%9d%e1%80%94%e1%80%ba%e1%80%80%e1%80%bc%e1%80%ae%e1%80%b8/",
        },
      ],
    },
    {
      index: 9,
      portfolio: "Ministry of Natural Resources and Environmental Conservation",
      category: MONREC_EN,
      logo: "ministries/monrec.png",
      url: "https://monrec.nugmyanmar.org/",
      members: [
        {
          name: "Dr. Tu Hkawng",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/TK.jpg",
          url: "https://monrec.nugmyanmar.org/biography-union-minister/",
        },
        {
          name: "Khun Bedu",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/KBD.jpg",
          url: "https://monrec.nugmyanmar.org/biography-deputy-minister/",
        },
      ],
    },
    {
      index: 10,
      portfolio: "Ministry of International Cooperation",
      category: MOIC_EN,
      logo: "ministries/moic.png",
      url: "https://moic.nugmyanmar.org/",
      members: [
        {
          name: "Dr. Sa Sa",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/SS.jpg",
          url: "https://moic.nugmyanmar.org/h-e-dr-sasa/",
        },
        {
          name: "Hkaung Naw",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/HKN.jpg",
          url: "https://moic.nugmyanmar.org/h-e-hkaung-naw/",
        },
      ],
    },
    {
      index: 11,
      portfolio: "Ministry of Women, Youths and Children Affairs",
      category: MOWYC_EN,
      logo: "ministries/mowyc.png",
      url: "https://mowyca.nugmyanmar.org/",
      members: [
        {
          name: "Naw Susanna Hla Hla Soe",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/NSHHS.jpg",
          url: "https://mowyca.nugmyanmar.org/about/minister",
        },
        {
          name: "Daw Ei Thinzar Maung",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/ETZM.jpg",
          url: "https://mowyca.nugmyanmar.org/about/deputy-minister",
        },
      ],
    },
    {
      index: 12,
      portfolio: "Ministry of Labour",
      category: MOL_EN,
      logo: "ministries/mol.png",
      url: "https://mol.nugmyanmar.org/",
      members: [
        {
          name: "Nai Tun Pe (a) Nai Suwunna",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/NTWN.jpg",
          url: "https://mol.nugmyanmar.org/ministers/union-minister-biography/",
        },
        {
          name: "U Kyaw Ni (a) U Kyaw Kyaw",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/UKN.jpg",
          url: "https://mol.nugmyanmar.org/ministers/union-deputy-minister-biography/",
        },
      ],
    },
    {
      index: 13,
      portfolio: "Ministry of Justice",
      category: MOJ_EN,
      logo: "ministries/moj.png",
      url: "",
      members: [
        {
          name: "U Thein Oo",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/UTO.jpg",
          url: "",
        },
      ],
    },
    {
      index: 14,
      portfolio: "Ministry of Communications, Information & Technology",
      category: MOCIT_EN,
      logo: "ministries/mocit.png",
      url: "https://mocit.nugmyanmar.org/",
      members: [
        {
          name: "U Htin Lin Aung",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/UHLA.jpg",
          url: "https://mocit.nugmyanmar.org/union-minister/",
        },
      ],
    },
    {
      index: 15,
      portfolio: "Ministry of Electricity & Energy",
      category: MOEE_EN,
      logo: "ministries/moee.png",
      url: "https://moee.nugmyanmar.org/en/",
      members: [
        {
          name: "U Soe Thura Tun",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/USTRT.jpg",
          url: "https://moee.nugmyanmar.org/en/union-minister/",
        },
        {
          name: "Lahpai Maw Htun Awng",
          position: DEPUTY_MINISTER_EN,
          member_thumbnail: "ministers/deputies/MTA.jpg",
          url: "https://moee.nugmyanmar.org/en/deputy-minister/",
        },
      ],
    },
    {
      index: 16,
      portfolio: "Ministry of Commerce",
      category: MOC_EN,
      logo: "ministries/moc.png",
      url: "",
      members: [
        {
          name: "Daw Khin Ma Ma Myo",
          position: UNION_MINISTER_EN,
          member_thumbnail: "ministers/deputies/KMMM.jpg",
          url: "",
        },
      ],
    },
  ],
  GOV_EN,
  UNION_MINISTER_EN,
  DEPUTY_MINISTER_EN,
  MOD_EN,
  MOH_EN,
  MOPFI_EN,
  MOFA_EN,
  MOE_EN,
  MOHAI_EN,
  MOFUA_EN,
  MOHADM_EN,
  MOHR_EN,
  MONREC_EN,
  MOIC_EN,
  MOWYC_EN,
  MOL_EN,
  MCIT_EN,
  MOC_EN,

  MOJ_EN,
  MOEE_EN,
  MOCIT_EN,
};
