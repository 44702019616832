import React from "react";
import PropTypes from "prop-types";
import { heads_my } from "data/metadata/my";
import { heads_en } from "data/metadata/en";
import FileImage from "./file-image";
import classNames from "classnames";

const Heads = ({ lang }) => {
  const languages = require("../data/languages");
  let heads = heads_my;
  if (lang !== languages.defaultLangKey) {
    heads = heads_en;
  }

  let header_text = lang === languages.defaultLangKey ? "နိုင်ငံတော် အကြီးအကဲများ" : "Heads of Government";
  let header_description =
    lang === languages.defaultLangKey
      ? "ဖက်ဒရယ် ဒီမိုကရေစီ ပဋိညာဉ်အရ ပြည်ထောင်စု သမ္မတ မြန်မာနိုင်ငံတော် အမျိုးသားညီညွတ်ရေး အစိုးရအဖွဲ့တွင် ခန့်အပ်တာဝန်ပေးထားသော နိုင်ငံတော် နှင့် အစိုးရအဖွဲ့ ၏ အကြီးအကဲများ"
      : "Heads of National Unity Government of the Republic of the Union of Myanmar appointed in accordance with the Federal Democracy Charter";

  return (
    <div>
      <div className="container px-5 py-16 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">{header_text}</h1>
          <p className="lg:w-2/3 mx-auto leading-loose text-base">{header_description}</p>
        </div>
        <div className="flex flex-wrap -m-2">
          {heads.map((head, index) => {
            let isGovLogoImage = head.member_thumbnail.includes("GOVMYANMAR");
            return (
              <div key={index} className="p-2 lg:w-1/3 md:w-1/2 w-full">
                <a href={head.url}>
                  <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg cursor-pointer hover:bg-gray-50 hover:border-sanjuan-500">
                    <FileImage
                      alt={head.name}
                      filename={head.member_thumbnail}
                      imgStyle={{ borderRadius: "0.5rem" }}
                      className={classNames(
                        {
                          "w-16 h-16 md:w-24 md:h-24 bg-gray-100 object-cover object-center flex-shrink-0 rounded-lg mr-4": isGovLogoImage,
                        },
                        {
                          "w-20 h-20 md:w-32 md:h-32 bg-gray-100 object-cover object-center flex-shrink-0 rounded-lg mr-4": !isGovLogoImage,
                        }
                      )}
                    />
                    <div className="flex-grow leading-7">
                      <h2 className="text-gray-900 title-font font-medium text-base md:text-lg">{head.name}</h2>
                      <p className="text-gray-500 text-sm md:text-base">{head.portfolio}</p>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

Heads.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Heads;
