import React from "react";
import PropTypes from "prop-types";
import FileImage from "./file-image";
import { ministries_my } from "data/metadata/my";
import { ministries_en } from "data/metadata/en";
import { sortNumber } from "../utilities/common";

function Ministries({ lang }) {
  const languages = require("../data/languages");
  let header = lang === "en" ? "Ministries" : "ဝန်ကြီးဌာနများ";
  let description =
    lang === "en"
      ? "National Unity Government of Myanmar has appointed follow ministries"
      : "ပြည်ထောင်စု သမ္မတ မြန်မာနိုင်ငံတော် အမျိုးသားညီညွတ်ရေး အစိုးရအဖွဲ့သည် ဝန်ကြီးဌာနများအား အောက်ပါအတိုင်း ဖွဲ့စည်းသည်";

  let data = lang === languages.defaultLangKey ? ministries_my : ministries_en;
  return (
    <div>
      <div className="container px-5 py-16 mx-auto">
        <div className="flex flex-col text-center w-full mb-14">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">{header}</h1>
          <p className="lg:w-2/3 mx-auto leading-loose text-base">{description}</p>
        </div>

        {data
          .sort((a, b) => sortNumber(a.index, b.index))
          .map((item, index) => {
            return (
              <div key={index} className="mb-10">
                <div className="p-4 pb-8">
                  <div key={index} className="flex flex-wrap -m-2 mb-5 pb-0">
                    {/* ---------- Ministry Info ----------*/}
                    <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
                      {item.url ? (
                        <a href={item.url}>
                          <div className="h-full flex flex-col md:flex-row items-center border-gray-200 border p-4 rounded-lg cursor-pointer hover:bg-gray-50 hover:border-sanjuan-500">
                            <FileImage
                              alt={item.portfolio}
                              filename={item.logo}
                              className="w-16 h-16 md:w-20 md:h-20 object-cover object-center flex-shrink-0 mr-0 md:mr-4"
                              imgStyle={{ borderRadius: 0 }}
                            />
                            <div className="flex-grow leading-7">
                              <h2 className="text-gray-900 title-font font-medium text-base md:text-lg text-center md:text-left">
                                {item.portfolio}
                              </h2>
                            </div>
                          </div>
                        </a>
                      ) : (
                        <div className="h-full flex flex-col md:flex-row items-center border-gray-200 border p-4 rounded-lg">
                          <FileImage
                            alt={item.portfolio}
                            filename={item.logo}
                            className="w-16 h-16 md:w-20 md:h-20 object-cover object-center flex-shrink-0 mr-0 md:mr-4"
                            imgStyle={{ borderRadius: 0 }}
                          />
                          <div className="flex-grow leading-7">
                            <h2 className="text-gray-900 title-font font-medium text-base md:text-lg text-center md:text-left">
                              {item.portfolio}
                            </h2>
                          </div>
                        </div>
                      )}
                    </div>
                    {/*---------- Ministry Info End----------*/}

                    {/* ---------- Members Name ----------*/}
                    {item.members.map((member, mIndex) => (
                      <div key={mIndex} className="p-2 lg:w-1/3 md:w-1/2 w-full">
                        {member.url ? (
                          <a href={member.url}>
                            <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg cursor-pointer hover:bg-gray-50 hover:border-sanjuan-500">
                              <FileImage alt={member.name} filename={member.member_thumbnail} />
                              <div className="flex-grow leading-7">
                                <h2 className="text-gray-900 title-font font-medium text-base md:text-lg">
                                  {member.name}
                                </h2>
                                <p className="text-gray-500 text-sm md:text-base">{member.position}</p>
                              </div>
                            </div>
                          </a>
                        ) : (
                          <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                            <FileImage alt={member.name} filename={member.member_thumbnail} />
                            <div className="flex-grow leading-7">
                              <h2 className="text-gray-900 title-font font-medium text-base md:text-lg">
                                {member.name}
                              </h2>
                              <p className="text-gray-500 text-sm md:text-base">{member.position}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    {/*---------- Members Name End ----------*/}
                  </div>
                </div>
                <hr className="style-two" />
              </div>
            );
          })}
      </div>
    </div>
  );
}

Ministries.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Ministries;
